import React from 'react'

const Data = () => {
    return (
        <div className="home__data">
            <h1 className="home__title">
                Pratyush
                <svg width="38" height="38" className='home__hand' viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M59.4258 62.0942L60.84 63.5084L62.2542 62.0942L79.9342 44.4142C81.1132 43.2353 83.0068 43.2353 84.1858 44.4142C85.3647 45.5932 85.3647 47.4868 84.1858 48.6658L55.9058 76.9458C43.8068 89.0447 24.1532 89.0447 12.0542 76.9458C-0.0447385 64.8468 -0.0447385 45.1932 12.0542 33.0942L30.4542 14.6942C31.6231 13.5253 33.5279 13.5005 34.7601 14.6686C35.9247 15.8485 35.92 17.7316 34.7458 18.9058L18.4658 35.1858L17.0516 36.6L18.4658 38.0142L19.8658 39.4142C23.7647 43.3132 23.7647 49.6468 19.8658 53.5458L18.4516 54.96L19.8658 56.3742L22.7058 59.2142L24.12 60.6284L25.5342 59.2142C31.5845 53.1639 32.4164 43.8654 28.0395 36.9089L57.3342 7.61421C58.5132 6.43526 60.4068 6.43526 61.5858 7.61421C62.7647 8.79317 62.7647 10.6868 61.5858 11.8658L39.6658 33.7858L38.2617 35.1899L39.6557 36.604L42.4156 39.404L43.8298 40.8386L45.2542 39.4142L72.8142 11.8542C73.9932 10.6753 75.8868 10.6753 77.0658 11.8542C78.2447 13.0332 78.2447 14.9268 77.0658 16.1058L49.5058 43.6658L48.0916 45.08L49.5058 46.4942L52.3458 49.3342L53.76 50.7484L55.1742 49.3342L78.4942 26.0142C79.6732 24.8353 81.5668 24.8353 82.7458 26.0142C83.9247 27.1932 83.9247 29.0868 82.7458 30.2658L56.5858 56.4258L55.1716 57.84L56.5858 59.2542L59.4258 62.0942ZM86.91 66C85.9566 76.5421 77.5421 84.9566 67 85.91V83.9007C76.4319 82.9591 83.9591 75.4319 84.9007 66H86.91ZM3.09001 22C4.04336 11.4579 12.4579 3.04336 23 2.09001V4.09931C13.5681 5.04087 6.04087 12.5681 5.09931 22H3.09001Z" stroke="black" stroke-width="4"/>
</svg>

            </h1>
            <h3 className="home__subtitle">
                MERN Stack Dev
            </h3>
            <p className="home__description">
                I'm a creative designer and MERN stack developer based in India and I'm Very passionate and dedicated to my work.
            </p>
            <a href="https://www.instagram.com/eyepatch_5263/" className="button button--flex">
                Say Hello
                <svg
                    class="button__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                        fill="var(--container-color)"
                    ></path>
                    <path
                        d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                        fill="var(--container-color)"
                    ></path>
                </svg>
            </a>

        </div>
    )
}

export default Data
